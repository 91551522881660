<template>
  <div id="PolicyManagement">
    <CSTabBar
        :checkedTab="tabBarType"
        :tabbar="tabBarList"
        @changeTabBar="changeTabBar"
    ></CSTabBar>
    <CSTabBar
        v-show="tabBarType === 2"
        :checked-tab="grabType"
        :tabbar="grabSettings"
        @changeTabBar="grabChange"
    ></CSTabBar>
    <div
        v-show="grabType !== 1 && grabType !== 2 && tabBarType !== 3"
        ref="filterPanel"
        class="filter-panel"
    >
      <template v-if="tabBarType === 1">
        <CSSelect style="margin-right: 0">
          <el-date-picker
              v-model="startTime"
              :editable="false"
              format="yyyy-MM-dd HH:mm"
              placeholder="请选择开始时间"
              style="width: 184px"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm"
          >
          </el-date-picker>
        </CSSelect>
        <span class="inline-gray"></span>
        <CSSelect>
          <el-date-picker
              v-model="endTime"
              :editable="false"
              format="yyyy-MM-dd HH:mm"
              placeholder="请选择结束时间"
              style="width: 184px"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm"
          >
          </el-date-picker>
        </CSSelect>
      </template>
      <input
          v-model="queryCrawlWebsiteInfo.search"
          :placeholder="
          tabBarType == 2 ? '搜索机关单位名称' : '搜索机关单位名称/标题'
        "
          class="cs-input"
          type="text"
      />
      <CSSelect>
        <select
            v-model="queryCrawlWebsiteInfo.lever"
            :disabled="isDisable"
            style="width: 150px"
            @keydown="endDisable"
        >
          <option value="">全部级别</option>
          <option
              v-for="level in policyLevelList"
              :key="level.id"
              :value="level.id"
          >
            {{ level.name }}
          </option>
        </select>
      </CSSelect>
      <div style="position: relative; display: inline-block">
        <div @click="switchVisible">
          <CSSelect>
            <div
                style="
                width: 123px;
                font-size: 14px;
                padding-left: 10px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              "
            >
              {{ regionName || "地区不限" }}
            </div>
          </CSSelect>
        </div>
        <div
            v-if="regionVisible"
            class="region"
            @click="(e) => e.stopPropagation()"
        >
          <CSSelect style="margin-bottom: 0">
            <select v-model="province" @change="onProvince">
              <option value="">不限(省)</option>
              <option
                  v-for="(province, index) in ProvinceList"
                  :key="index"
                  :value="province"
              >
                {{ province.provinceName }}
              </option>
            </select>
          </CSSelect>
          <CSSelect style="margin-bottom: 0">
            <select v-model="city" @change="onCity">
              <option value="">不限(市)</option>
              <option
                  v-for="city in cityList"
                  :key="city.cityName"
                  :value="city"
              >
                {{ city.cityName }}
              </option>
            </select>
          </CSSelect>
          <CSSelect style="margin-bottom: 0">
            <select v-model="district" @change="onDistrict">
              <option value="">不限(区县)</option>
              <option v-for="item in districtList" :key="item.id" :value="item">
                {{ item.name }}
              </option>
            </select>
          </CSSelect>
          <button
              class="btn btn-primary"
              style="padding: 0; margin-bottom: 0"
              @click="onRegionConfirm"
          >
            确定
          </button>
        </div>
      </div>
      <button
          class="btn btn-primary"
          style="width: 50px; height: 30px; padding: 0"
          @click="onQueryState"
      >
        查询
      </button>
    </div>
    <div
        v-if="tabBarType === 2 && grabType !== 0"
        ref="grabFilterPanel"
        class="filter-panel"
    >
      <template v-if="grabType === 1 || grabType === 2">
        <CSSelect style="margin-right: 0">
          <el-date-picker
              v-model="queryTestGrab.startTime"
              :editable="false"
              format="yyyy-MM-dd HH:mm"
              placeholder="请选择开始时间"
              style="width: 184px"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm"
          >
          </el-date-picker>
        </CSSelect>
        <span class="inline-gray"></span>
        <CSSelect>
          <el-date-picker
              v-model="queryTestGrab.endTime"
              :editable="false"
              format="yyyy-MM-dd HH:mm"
              placeholder="请选择结束时间"
              style="width: 184px"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm"
          >
          </el-date-picker>
        </CSSelect>
        <input
            v-model="queryTestGrab.search"
            class="cs-input"
            placeholder="搜索机关单位名称/标题"
            type="text"
        />
        <CSSelect>
          <select v-model="queryTestGrab.lever">
            <option value="">全部级别</option>
            <option
                v-for="level in policyLevelList"
                :key="level.id"
                :value="level.id"
            >
              {{ level.name }}
            </option>
          </select>
        </CSSelect>
        <div style="position: relative; display: inline-block">
          <div @click="switchVisible">
            <CSSelect>
              <div
                  style="
                  width: 123px;
                  font-size: 14px;
                  padding-left: 10px;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                "
              >
                {{ regionName || "地区不限" }}
              </div>
            </CSSelect>
          </div>
          <div v-if="regionVisible" class="region">
            <CSSelect style="margin-bottom: 0">
              <select v-model="province" @change="onProvince">
                <option value="">不限(省)</option>
                <option
                    v-for="(province, index) in ProvinceList"
                    :key="index"
                    :value="province"
                >
                  {{ province.provinceName }}
                </option>
              </select>
            </CSSelect>
            <CSSelect style="margin-bottom: 0">
              <select v-model="city" @change="onCity">
                <option value="">不限(市)</option>
                <option
                    v-for="city in cityList"
                    :key="city.cityName"
                    :value="city"
                >
                  {{ city.cityName }}
                </option>
              </select>
            </CSSelect>
            <CSSelect style="margin-bottom: 0">
              <select v-model="district" @change="onDistrict">
                <option value="">不限(区县)</option>
                <option
                    v-for="item in districtList"
                    :key="item.id"
                    :value="item"
                >
                  {{ item.name }}
                </option>
              </select>
            </CSSelect>
            <button
                class="btn btn-primary"
                style="padding: 0; margin-bottom: 0"
                @click="onRegionConfirm"
            >
              确定
            </button>
          </div>
        </div>
        <CSSelect>
          <select v-model="queryTestGrab.captureStatus">
            <option value="">全部抓取状态</option>
            <option v-for="grab in grabState" :key="grab.id" :value="grab.id">
              {{ grab.name }}
            </option>
          </select>
        </CSSelect>
        <CSSelect>
          <select v-model="queryTestGrab.releaseStatus">
            <option value="">全部发布状态</option>
            <option
                v-for="release in releaseState"
                :key="release.id"
                :value="release.id"
            >
              {{ release.name }}
            </option>
          </select>
        </CSSelect>
        <button
            class="btn btn-primary"
            style="width: 50px; height: 30px; padding: 0"
            type="button"
            @click="
            grabType === 1 ? queryTestGrabList() : queryAutomaticGrabList()
          "
        >
          查询
        </button>
      </template>
    </div>
    <div class="result-panel">
      <div v-show="tabBarType === 0">
        <CSTable :thead-top="filterHeight">
          <template v-slot:header>
            <div
                class="table-header-panel"
                style="
                display: flex;
                justify-content: space-between;
                align-items: center;
              "
            >
              <div>
                <svg
                    aria-hidden="true"
                    class="icon"
                    style="font-size: 16px; margin-right: 10px"
                >
                  <use xlink:href="#icon-menua-zu92"></use>
                </svg>
                抓取的政策将会出现在此列表，自动抓取的政策到达指定发布时间后，政策将会自动发布（也可手动发布），测试抓取的政策请手动发布。
              </div>
              <button
                  class="btn btn-primary btn-sm sticky-right"
                  style="min-width: 80px; height: 30px; padding: 0"
                  type="button"
                  @click="releasePolicy"
              >
                发布政策
              </button>
            </div>
          </template>
          <template v-slot:thead>
            <tr>
              <th>抓取时间</th>
              <th>标题</th>
              <th>机关单位名称</th>
              <th>级别</th>
              <th>适用地区</th>
              <th>操作</th>
            </tr>
          </template>
          <template v-slot:tbody>
            <template v-if='!isLoading'>
              <tr v-for="policy in policyToBeIssuedList" :key="policy.id">
                <td class="date-td">{{ policy.date }}</td>
                <td>
                  <div
                      class="allow-click"
                      style="
                    width: 200px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  "
                      @click="queryPolicyInfo(policy.id)"
                  >
                    {{ policy.headline }}
                  </div>
                </td>
                <td>{{ policy.organizationName }}</td>
                <td>{{ policyLevel[policy.lever] }}</td>
                <td>{{ policy.zoneApplication }}</td>
                <td>
                  <div class="btn-group">
                    <button
                        aria-expanded="false"
                        aria-haspopup="true"
                        class="btn btn-primary dropdown-toggle"
                        data-toggle="dropdown"
                        type="button"
                    >
                      操作
                    </button>
                    <ul class="dropdown-menu">
                      <li>
                        <a
                            style="width: 100%"
                            @click="addEditPolicy(2, policy.id)"
                        >修改政策</a
                        >
                      </li>
                      <li>
                        <a
                            style="width: 100%"
                            @click="
                          deleteId = policy.id;
                          policyToBeIssuedVisible = true;
                        "
                        >删除政策</a
                        >
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
            </template>

            <tr v-else>
              <td :colspan="12">
                <div style="text-align: center">暂无数据</div>
              </td>
            </tr>
          </template>
        </CSTable>
      </div>
      <div v-show="tabBarType === 1">
        <CSTable :thead-top="filterHeight">
          <template v-slot:header>
            <div class="table-header-panel text-right">
              <button
                  class="btn btn-primary btn-sm sticky-right"
                  style="width: 100px; height: 30px; padding: 0"
                  type="button"
                  @click="addEditPolicy(1)"
              >
                <svg aria-hidden="true" class="icon">
                  <use xlink:href="#icon-menujiahao"></use>
                </svg>
                添加政策
              </button>
            </div>
          </template>
          <template v-slot:thead>
            <tr>
              <th>发布日期</th>
              <th>标题</th>
              <th>机关单位名称</th>
              <th>级别</th>
              <th>适用地区</th>
              <th>操作</th>
            </tr>
          </template>
          <template v-slot:tbody>
            <tr
                v-for="policyPublished in policyPublishedList"
                :key="policyPublished.id"
            >
              <td class="date-td">{{ policyPublished.date }}</td>
              <td>
                <div
                    class="allow-click"
                    style="
                    width: 200px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  "
                    @click="queryPolicyInfo(policyPublished.id)"
                >
                  {{ policyPublished.headline }}
                </div>
              </td>
              <td>{{ policyPublished.organizationName }}</td>
              <td>{{ policyLevel[policyPublished.lever] }}</td>
              <td>{{ policyPublished.zoneApplication }}</td>
              <td>
                <div class="btn-group">
                  <button
                      aria-expanded="false"
                      aria-haspopup="true"
                      class="btn btn-primary dropdown-toggle"
                      data-toggle="dropdown"
                      type="button"
                  >
                    操作
                  </button>
                  <ul class="dropdown-menu">
                    <li @click="addEditPolicy(2, policyPublished.id)">
                      <a style="width: 100%">修改政策</a>
                    </li>
                    <li>
                      <a
                          style="width: 100%"
                          @click="
                          deleteId = policyPublished.id;
                          policyToBeIssuedVisible = true;
                        "
                      >删除政策</a
                      >
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
          </template>
        </CSTable>
      </div>
      <div v-show="tabBarType === 2">
        <div v-show="grabType === 0">
          <CSTable :thead-top="filterHeight">
            <template v-slot:header>
              <div class="table-header-panel text-right">
                <div class="sticky-right" style="display: inline-block">
                  <button
                      class="btn btn-primary btn-sm"
                      style="margin-right: 20px"
                      type="button"
                      @click="addWebsite"
                  >
                    <svg aria-hidden="true" class="icon">
                      <use xlink:href="#icon-menujiahao"></use>
                    </svg>
                    添加网站
                  </button>
                  <button
                      class="btn btn-primary btn-sm"
                      type="button"
                      @click="importExcel"
                  >
                    Excel导入网站
                  </button>
                </div>
              </div>
            </template>
            <template v-slot:thead>
              <tr>
                <th>添加时间</th>
                <th>机关单位名称</th>
                <th>级别</th>
                <th>适用地区</th>
                <th>网址</th>
                <th>过滤词语</th>
                <th>是否可以抓取</th>
                <th>抓取状态</th>
                <th>操作</th>
              </tr>
            </template>
            <template v-slot:tbody>
              <tr v-for="web in webList" :key="web.id">
                <td class="date-td">{{ web.createTime }}</td>
                <td>{{ web.organizationName }}</td>
                <td>{{ policyLevel[web.lever] }}</td>
                <td>{{ web.zoneApplication }}</td>
                <td>
                  <a
                      :href="web.websiteUrl"
                      class="allow-click"
                      style="
                      width: 300px;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    "
                      target="_blank"
                  >
                    {{ web.websiteUrl }}
                  </a>
                </td>
                <td>
                  <span
                      v-if="web.isFilter"
                      class="allow-click"
                      @click="queryFilterListText(web.id)"
                  >查看</span
                  >
                  <span v-else>-</span>
                </td>
                <td>
                  {{ web.isPass ? "是" : "否" }}
                </td>
                <td>{{ captureStateList[web.captureStatus] }}</td>
                <td>
                  <div class="btn-group">
                    <button
                        aria-expanded="false"
                        aria-haspopup="true"
                        class="btn btn-primary dropdown-toggle"
                        data-toggle="dropdown"
                        type="button"
                    >
                      操作
                    </button>
                    <ul class="dropdown-menu">
                      <li>
                        <a style="width: 100%" @click="editWebsite(web)"
                        >修改</a
                        >
                      </li>
                      <li>
                        <a
                            style="width: 100%"
                            @click="
                            deleteByConditionVisible = true;
                            deleteId = web.id;
                          "
                        >删除</a
                        >
                      </li>
                      <li>
                        <a
                            style="width: 100%"
                            @click="
                            addFilterWordsVisible = true;
                            addFilterWordsId = web.id;
                          "
                        >过滤词语</a
                        >
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
            </template>
          </CSTable>
        </div>
        <div v-show="grabType === 1">
          <CSTable :thead-top="grabFilterHeight">
            <template v-slot:header>
              <div
                  class="table-header-panel"
                  style="
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <div>
                  <span style="margin-right: 20px">抓取年份下限</span>
                  <CSSelect style="margin-right: 20px">
                    <select v-model="particularYear">
                      <option
                          v-for="particularYear in particularYearList"
                          :key="particularYear"
                          :value="particularYear"
                      >
                        {{ particularYear }}年
                      </option>
                    </select>
                  </CSSelect>
                  <button
                      class="btn btn-primary"
                      style="
                      margin-right: 20px;
                      height: 30px;
                      width: 50px;
                      padding: 0;
                    "
                      type="button"
                      @click="setGrabYear"
                  >
                    保存
                  </button>
                  <svg
                      aria-hidden="true"
                      class="icon"
                      style="font-size: 16px; margin-right: 10px"
                  >
                    <use xlink:href="#icon-menua-zu92"></use>
                  </svg>
                  测试抓取后，将立刻抓取所有网站指定年份以及之后的所有政策，抓到的政策将会放在待发布列表，等待发布。
                </div>
                <div style="display: flex">
                  <div
                      v-if="testGrabInProgressVisible"
                      class="grabbing"
                      style="width: 100px"
                  >
                    测试抓取中
                  </div>
                  <button
                      class="btn btn-primary"
                      style="height: 30px; width: 80px; padding: 0"
                      type="button"
                      @click="testGrab"
                  >
                    测试抓取
                  </button>
                </div>
              </div>
            </template>
            <template v-slot:thead>
              <tr>
                <th>抓取时间</th>
                <th>抓取机关单位名称</th>
                <th>级别</th>
                <th>发布日期</th>
                <th style="width: 200px">标题</th>
                <th>适用地区</th>
                <th>抓取状态</th>
                <th>发布状态</th>
              </tr>
            </template>
            <template v-slot:tbody>
              <template v-if="!isLoadingC ">
                <tr v-for="testGrab in testGrabList" :key="testGrab.id">
                  <td class="full-date-td">{{ testGrab.captureDate }}</td>
                  <td>{{ testGrab.organization_name }}</td>
                  <td>{{ policyLevel[testGrab.lever] }}</td>
                  <td class="date-td">{{ testGrab.releaseDate }}</td>
                  <td>
                    <div
                        class="allow-click"
                        style="
                      width: 200px;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    "
                        @click="queryPolicyInfo(testGrab.id)"
                    >
                      {{ testGrab.headline }}
                    </div>
                  </td>
                  <td>{{ testGrab.zoneApplication }}</td>
                  <td>
                    <span v-if="testGrab.captureStatus === 1">成功</span>
                    <span
                        v-else
                        class="allow-click"
                        @click="queryFailCause(testGrab.id)"
                    >失败</span
                    >
                  </td>

                  <td>
                    {{ testGrab.releaseStatus === 1 ? "已发布" : "未发布" }}
                  </td>
                </tr>
              </template>
              <tr v-else>
                <td :colspan="12">
                  <div style="text-align: center">暂无数据</div>
                </td>
              </tr>
            </template>
          </CSTable>
        </div>
        <div v-show="grabType === 2">
          <CSTable :thead-top="grabFilterHeight">
            <template v-slot:header>
              <div
                  class="table-header-panel"
                  style="
                  display: flex;
                  justify-content: space-between;
                  align-items: start;
                "
              >
                <div>
                  <span style="margin-right: 20px">抓取时间</span>
                  <CSSelect>
                    <select v-model="autoGrabTimeHour" :disabled="enable">
                      <option
                          v-for="autoGrabTimeHour in autoGrabTimeHourList"
                          :key="autoGrabTimeHour"
                          :value="autoGrabTimeHour"
                      >
                        {{ autoGrabTimeHour }}
                      </option>
                    </select>
                  </CSSelect>
                  :
                  <CSSelect style="margin-right: 30px">
                    <select v-model="autoGrabTimeMinute" :disabled="enable">
                      <option
                          v-for="minute in autoGrabTimeMinuteList"
                          :key="minute"
                          :value="minute"
                      >
                        {{ minute }}
                      </option>
                    </select>
                  </CSSelect>
                  <span style="margin-right: 20px">发布时间</span>
                  <CSSelect>
                    <select v-model="releaseGrabHour" :disabled="enable">
                      <option
                          v-for="hour in releaseGrabHourList"
                          :key="hour"
                          :value="hour"
                      >
                        {{ hour }}
                      </option>
                    </select>
                  </CSSelect>
                  :
                  <CSSelect>
                    <select v-model="releaseGrabMinute" :disabled="enable">
                      <option
                          v-for="minute in autoGrabTimeMinuteList"
                          :key="minute"
                          :value="minute"
                      >
                        {{ minute }}
                      </option>
                    </select>
                  </CSSelect>
                  <button
                      v-if="!enable"
                      class="btn btn-primary"
                      style="
                      width: 50px;
                      margin: 0 30px;
                      padding: 0;
                      height: 30px;
                    "
                      type="button"
                      @click="setupTime"
                  >
                    保存
                  </button>
                  <div style="margin-top: 10px">
                    <svg
                        aria-hidden="true"
                        class="icon"
                        style="font-size: 16px; margin-right: 10px"
                    >
                      <use xlink:href="#icon-menua-zu92"></use>
                    </svg>
                    启用自动抓取后，会在指定抓取时间抓取昨天发布的政策，抓到的政策将放在待发布列表，到达指定发布时间后，政策将会自动发布（也可手动发布）。
                  </div>
                </div>
                <div
                    class="sticky-right"
                    style="display: flex; background: #fff"
                >
                  <div v-if="enable" class="grabbing">抓取中</div>
                  <button
                      :class="{ 'btn btn-primary': true, empty: enable }"
                      style="height: 30px; width: 100px; padding: 0"
                      type="button"
                      @click="enableGrab"
                  >
                    {{ enable ? "关闭自动抓取" : "启动自动抓取" }}
                  </button>
                </div>
              </div>
            </template>
            <template v-slot:thead>
              <tr>
                <th>抓取时间</th>
                <th>抓取机关单位名称</th>
                <th>级别</th>
                <th>发布日期</th>
                <th>标题</th>
                <th>适用地区</th>
                <th>抓取状态</th>
                <th>发布状态</th>
              </tr>
            </template>
            <template v-slot:tbody>
              <tr
                  v-for="automaticGrab in automaticGrabList"
                  :key="automaticGrab.id"
              >
                <td class="full-date-td">{{ automaticGrab.captureDate }}</td>
                <td>{{ automaticGrab.organization_name }}</td>
                <td>{{ policyLevel[automaticGrab.lever] }}</td>
                <td>{{ automaticGrab.releaseDate }}</td>
                <td>
                  <div
                      class="allow-click"
                      style="
                      width: 200px;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    "
                      @click="queryPolicyInfo(automaticGrab.id)"
                  >
                    {{ automaticGrab.headline }}
                  </div>
                </td>
                <td>{{ automaticGrab.zoneApplication }}</td>
                <td>
                  <span v-if="automaticGrab.captureStatus === 1">成功</span>
                  <span
                      v-else
                      class="allow-click"
                      @click="queryFailCause(automaticGrab.id)"
                  >失败</span
                  >
                </td>
                <td>
                  {{ automaticGrab.releaseStatus === 1 ? "已发布" : "未发布" }}
                </td>
              </tr>
            </template>
          </CSTable>
        </div>
      </div>
      <div v-show="tabBarType === 3" style="padding: 15px 30px">
        <div style="margin-bottom: 15px">
          <svg
              aria-hidden="true"
              class="icon"
              style="font-size: 16px; margin-right: 10px"
          >
            <use xlink:href="#icon-menua-zu92"></use>
          </svg>
          用户通过“热搜词”，快速搜索到相关政策。
        </div>
        <div class="hotSearch">
          <div v-if="hotSearchInfo.searchName1" style="margin-right: 20px">
            <span class="text">{{ hotSearchInfo.searchName1 }}</span>
            <svg
                aria-hidden="true"
                class="icon"
                style="font-size: 20px"
                @click="hotSearchInfo.searchName1 = ''"
            >
              <use xlink:href="#icon-menua-zu60"></use>
            </svg>
          </div>
          <div v-if="hotSearchInfo.searchName2" style="margin-right: 20px">
            <span class="text">{{ hotSearchInfo.searchName2 }}</span>
            <svg
                aria-hidden="true"
                class="icon"
                style="font-size: 20px"
                @click="hotSearchInfo.searchName2 = ''"
            >
              <use xlink:href="#icon-menua-zu60"></use>
            </svg>
          </div>
          <div v-if="hotSearchInfo.searchName3" style="margin-right: 20px">
            <span class="text">{{ hotSearchInfo.searchName3 }}</span>
            <svg
                aria-hidden="true"
                class="icon"
                style="font-size: 20px"
                @click="hotSearchInfo.searchName3 = ''"
            >
              <use xlink:href="#icon-menua-zu60"></use>
            </svg>
          </div>
          <div v-if="hotSearchInfo.searchName4" style="margin-right: 20px">
            <span class="text">{{ hotSearchInfo.searchName4 }}</span>
            <svg
                aria-hidden="true"
                class="icon"
                style="font-size: 20px"
                @click="hotSearchInfo.searchName4 = ''"
            >
              <use xlink:href="#icon-menua-zu60"></use>
            </svg>
          </div>
          <button
              class="btn btn-primary"
              style="width: 100px; height: 30px; padding: 0"
              @click="hotSearch"
          >
            <svg aria-hidden="true" class="icon">
              <use xlink:href="#icon-menujiahao"></use>
            </svg>
            添加热搜词
          </button>
        </div>
        <button
            class="btn btn-primary"
            style="margin-top: 30px; padding: 0; height: 30px; width: 50px"
            @click="addHotSearch"
        >
          保存
        </button>
      </div>

      <Pagination
          v-if="tabBarType !== 3"
          componentName="Pagination"
          name="pagination"
      >
      </Pagination>
    </div>

    <CSDialog
        :dialog-visible="releasePolicyVisible"
        dialog-confirm-btn-text="确定"
        dialog-header-class=" "
        dialog-header-icon="icon-menua-zu13"
        dialog-width="460px"
        @onClose="releasePolicyVisible = false"
        @onConfirm="releasePolicyConfirm"
    >
      <template v-slot:dialog-content>
        <div style="text-align: center; font-size: 24px; padding: 30px 0">
          确定发布{{ policyTotal }}条政策吗？
        </div>
      </template>
    </CSDialog>

    <CSDialog
        :dialog-title="addWebTitle"
        :dialog-visible="addWebsiteVisible"
        dialog-width="860px"
        @onClose="addWebsiteClose"
        @onConfirm="addeditByConditionBtn"
    >
      <template v-slot:dialog-content>
        <div style="padding: 20px 30px">
          <div style="display: flex; margin-bottom: 30px">
            <div class="notice_title" style="width: 144px">机关单位名称</div>
            <input
                v-model="addCrawlWebsiteInfo.organizationName"
                class="hy-input"
                placeholder="请输入"
                type="text"
            />
          </div>
          <div style="display: flex; margin-bottom: 30px">
            <div class="notice_title" style="width: 144px">级别</div>
            <div class="select">
              <div class="level">
                <input
                    id="nationalLevel"
                    v-model="addCrawlWebsiteInfo.lever"
                    :value="1"
                    name="level"
                    type="radio"
                />
                <label for="nationalLevel">国家级</label>
              </div>
              <div class="level">
                <input
                    id="provincialLevel"
                    v-model="addCrawlWebsiteInfo.lever"
                    :value="2"
                    name="level"
                    type="radio"
                />
                <label for="provincialLevel">省级</label>
              </div>
              <div class="level">
                <input
                    id="MunicipalLevel"
                    v-model="addCrawlWebsiteInfo.lever"
                    :value="3"
                    name="level"
                    type="radio"
                />
                <label for="MunicipalLevel">市级</label>
              </div>
              <div class="level">
                <input
                    id="DistrictLevel"
                    v-model="addCrawlWebsiteInfo.lever"
                    :value="4"
                    name="level"
                    type="radio"
                />
                <label for="DistrictLevel">区县级</label>
              </div>
            </div>
          </div>
          <div
              v-if="
              addCrawlWebsiteInfo.lever !== 1 && addCrawlWebsiteInfo.lever != ''
            "
              style="display: flex; margin-bottom: 30px; font-size: 24px"
          >
            <div class="notice_title" style="width: 144px">适用地区</div>
            <CSSelect height="40px" i-width="36px" style="margin-right: 20px">
              <select
                  v-model="addWebRegionInfo.provinceInfo"
                  style="width: 170px;color: #999999"
                  @change="addWebProvince"
              >
                <option value="">请选择省</option>
                <option
                    v-for="province in ProvinceList"
                    :key="province.provinceName"
                    :value="province"
                >
                  {{ province.provinceName }}
                </option>
              </select>
            </CSSelect>
            <CSSelect
                v-if="
                addCrawlWebsiteInfo.lever === 3 ||
                addCrawlWebsiteInfo.lever === 4
              "
                height="40px"
                i-width="36px"
                style="margin-right: 20px"
            >
              <select
                  v-model="addWebRegionInfo.cityInfo"
                  style="width: 170px;color: #999999"
                  @change="addWebCity"
              >
                <option value="">请选择市</option>
                <option
                    v-for="city in addWebObj.cityList"
                    :key="city.cityName"
                    :value="city"
                >
                  {{ city.cityName }}
                </option>
              </select>
            </CSSelect>
            <CSSelect
                v-if="addCrawlWebsiteInfo.lever === 4"
                height="40px"
                i-width="36px"
                style="margin-right: 20px"
            >
              <select
                  v-model="addWebRegionInfo.districtInfo"
                  style="width: 170px;color: #999999"
                  @change="addWebDistrict"
              >
                <option value="">请选择区县</option>
                <option
                    v-for="district in addWebObj.districtList"
                    :key="district.id"
                    :value="district"
                >
                  {{ district.name }}
                </option>
              </select>
            </CSSelect>
          </div>
          <div style="display: flex">
            <div class="notice_title" style="width: 144px">网址</div>
            <input
                v-model="addCrawlWebsiteInfo.websiteUrl"
                class="hy-input"
                placeholder="请输入"
                type="text"
            />
          </div>
        </div>
      </template>
    </CSDialog>

    <CSDialog
        :dialog-visible="excelVisible"
        dialog-confirm-btn-text="上传"
        dialog-title="Excel导入网站"
        dialog-width="550px"
        @onClose="
        excelVisible = false;
        excelName = '';
        fromData = '';
      "
        @onConfirm="uploadExcel"
    >
      <template v-slot:dialog-content>
        <div style="padding: 20px 30px">
          <div style="display: flex; margin-bottom: 30px">
            <div class="notice_title" style="width: 120px">选择Excel</div>
            <input
                v-model="excelName"
                class="hy-input"
                placeholder="请选择"
                style="width: 240px; margin-right: 20px"
                type="text"
            />
            <button
                class="btn btn-primary"
                style="height: 40px; width: 80px; font-size: 24px; padding: 0"
                type="button"
                @click="upLoadExcelFile"
            >
              浏览
            </button>
            <input
                id="up_excel_file"
                class="file"
                hidden
                type="file"
                @change="chooseExcelFile($event)"
            />
          </div>
          <div style="display: flex">
            <div class="notice_title" style="width: 110px">模板</div>
            <span
                class="allow-click"
                style="font-size: 24px"
                @click="downloadExcel"
            >下载</span
            >
          </div>
        </div>
      </template>
    </CSDialog>

    <CSDialog
        :dialog-visible="testGrabVisible"
        dialog-confirm-btn-text="确定"
        dialog-header-class=" "
        dialog-header-icon="icon-menua-zu13"
        dialog-title=""
        dialog-width="560px"
        @onClose="testGrabVisible = false"
        @onConfirm="testCrawSite"
    >
      <template v-slot:dialog-content>
        <div style="padding: 20px 30px; font-size: 24px">
          确定测试抓取后，将立刻抓取所有网站{{
            particularYear
          }}年以及之后的所有政策，抓到的政策将会放在待发布列表。
        </div>
      </template>
    </CSDialog>

    <CSDialog
        :dialog-visible="enableGrabVisible"
        dialog-confirm-btn-text="确定"
        dialog-header-class=" "
        dialog-header-icon="icon-menua-zu13"
        dialog-title=""
        dialog-width="560px"
        @onClose="enableGrabVisible = false"
        @onConfirm="enableAutomaticGrab"
    >
      <template v-slot:dialog-content>
        <div v-if="!enable" style="padding: 20px 30px; font-size: 24px">
          启用自动抓取后，系统将在每天{{
            autoGrabTimeHour + ":" + autoGrabTimeMinute
          }}抓取前一天发布的政策，并在当天{{
            releaseGrabHour + ":" + releaseGrabMinute
          }}将所抓政策自动发布。
        </div>
        <div
            v-else
            style="padding: 20px 30px; font-size: 24px; text-align: center"
        >
          确定关闭自动抓取吗？
        </div>
      </template>
    </CSDialog>

    <CSDialog
        :dialog-visible="hotSearchVisible"
        dialog-title="添加热搜词"
        dialog-width="440px"
        @onClose="(hotSearchVisible = false), (hotSearchName = '')"
        @onConfirm="saveHotSearch"
    >
      <template v-slot:dialog-content>
        <div style="padding: 20px 30px; display: flex">
          <div class="notice_title">热搜词</div>
          <input
              v-model="hotSearchName"
              class="hy-input"
              maxlength="10"
              placeholder="限2-10字"
              style="width: 200px"
              type="text"
          />
        </div>
      </template>
    </CSDialog>

    <CSDialog
        :dialogVisible="deleteByConditionVisible"
        dialog-confirm-btn-text="确定"
        dialog-header-class=" "
        dialog-header-icon="icon-menua-zu13"
        dialogTitle="提示"
        dialogWidth="480px"
        @onClose="deleteByConditionVisible = false"
        @onConfirm="deleteByCondition"
    >
      <div
          slot="dialog-content"
          style="padding: 20px 30px; font-size: 24px; text-align: center"
      >
        确定删除吗?
      </div>
    </CSDialog>

    <CSDialog
        :dialog-title="addPolicyDialogTitle"
        :dialog-visible="editPolicyVisible"
        :is-submitting="isSubmitting"
        dialog-width="920px"
        @onClose="addEditClose"
        @onConfirm="judgeAddEdit"
    >
      <template v-slot:dialog-content>
        <div style="padding: 20px 30px">
          <div style="margin-bottom: 23px; font-size: 24px">
            <div class="notice_title" style="width: 96px">发布日期</div>
            <CSSelect height="40px" i-width="36px">
              <el-date-picker
                  v-model="editPolicyByInfo.releaseDate"
                  :editable="false"
                  format="yyyy-MM-dd"
                  placeholder="请填写发布日期"
                  style="width: 250px; font-size: 24px; line-height: 32px"
                  type="datetime"
                  value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </CSSelect>
          </div>
          <div style="margin-bottom: 23px">
            <div class="notice_title" style="width: 96px">标题</div>
            <input
                v-model="editPolicyByInfo.headline"
                class="hy-input"
                placeholder="请输入"
                type="text"
            />
          </div>
          <div style="margin-bottom: 23px; display: flex">
            <div class="notice_title" style="width: 96px">级别</div>
            <div class="select">
              <div class="level">
                <input
                    id="nationalLevel"
                    v-model="editPolicyByInfo.lever"
                    :value="1"
                    name="editLevel"
                    type="radio"
                    @click="getWebUrl('中国')"
                />
                <label for="nationalLevel">国家级</label>
              </div>
              <div class="level">
                <input
                    id="provincialLevel"
                    v-model="editPolicyByInfo.lever"
                    :value="2"
                    name="editLevel"
                    type="radio"
                />
                <label for="provincialLevel">省级</label>
              </div>
              <div class="level">
                <input
                    id="MunicipalLevel"
                    v-model="editPolicyByInfo.lever"
                    :value="3"
                    name="editLevel"
                    type="radio"
                />
                <label for="MunicipalLevel">市级</label>
              </div>
              <div class="level">
                <input
                    id="DistrictLevel"
                    v-model="editPolicyByInfo.lever"
                    :value="4"
                    name="editLevel"
                    type="radio"
                />
                <label for="DistrictLevel">区县级</label>
              </div>
            </div>
          </div>
          <div
              v-if="editPolicyByInfo.lever !== 1 && editPolicyByInfo.lever != ''"
              style="margin-bottom: 23px; font-size: 24px"
          >
            <div class="notice_title" style="width: 96px">适用地区</div>
            <CSSelect
                height="40px"
                i-width="36px"
                style="width: 170px; margin-right: 20px"
            >
              <select v-model="province" @change="onProvince">
                <option value="">请选择省</option>
                <option
                    v-for="(province, index) in ProvinceList"
                    :key="index"
                    :value="province"
                >
                  {{ province.provinceName }}
                </option>
              </select>
            </CSSelect>
            <CSSelect
                v-if="
                editPolicyByInfo.lever === 3 || editPolicyByInfo.lever === 4
              "
                height="40px"
                i-width="36px"
                style="width: 170px; margin-right: 20px"
            >
              <select v-model="city" @change="onCity">
                <option value="">请选择市</option>
                <option
                    v-for="city in cityList"
                    :key="city.cityName"
                    :value="city"
                >
                  {{ city.cityName }}
                </option>
              </select>
            </CSSelect>
            <CSSelect
                v-if="editPolicyByInfo.lever === 4"
                height="40px"
                i-width="36px"
                style="width: 170px; margin-right: 20px"
            >
              <select v-model="district" @change="onDistrict">
                <option value="">请选择区县</option>
                <option
                    v-for="item in districtList"
                    :key="item.id"
                    :value="item"
                >
                  {{ item.name }}
                </option>
              </select>
            </CSSelect>
          </div>
          <div style="display: flex; margin-bottom: 23px">
            <div class="notice_title" style="width: 96px">关联网址</div>
            <CSSelect height="40px" i-width="36px" style="font-size: 24px">
              <select v-model="editPolicyByInfo.websiteUrl">
                <option value="">请选择关联网址</option>
                <option
                    v-for="item in webUrlList"
                    :key="item.url"
                    :value="item.url"
                >
                  {{ item.organizationName }}
                </option>
              </select>
            </CSSelect>
          </div>
          <div style="display: flex; margin-bottom: 23px">
            <div class="notice_title" style="width: 96px">正文</div>
            <WangEditor
                parentComponent="editVisitingService"
                style="width: 750px"
            ></WangEditor>
          </div>
          <div style="display: flex; font-size: 24px">
            <div class="notice_title" style="width: 96px">附件</div>
            <div style="width: 720px">
              <div v-if="editPolicyByInfo.accessory.length > 0">
                <p
                    v-for="(enclosure, index) in editPolicyByInfo.accessory"
                    :key="enclosure.url"
                >
                  <a
                      :href="enclosure.url"
                      style="color: #1dafff; text-decoration: underline"
                      target="_blank"
                  >{{ enclosure.name }}</a
                  >
                  <svg
                      aria-hidden="true"
                      class="icon"
                      style="margin-left: 30px"
                      @click="deletePolicyFile(index)"
                  >
                    <use xlink:href="#icon-menushanchu"></use>
                  </svg>
                </p>
              </div>
              <div>
                <button
                    class="btn btn-primary"
                    style="
                    width: 144px;
                    padding: 0;
                    height: 40px;
                    font-size: 24px;
                    border-radius: 4px;
                    box-shadow: 0px 5px 10px rgba(0, 182, 148, 0.3);
                  "
                    @click="upLoadFile"
                >
                  <svg aria-hidden="true" class="icon">
                    <use xlink:href="#icon-menujiahao"></use>
                  </svg>
                  上传附件
                </button>
              </div>
            </div>
          </div>
          <input
              id="up_load_file"
              class="file"
              hidden
              type="file"
              @change="chooseFile($event)"
          />
        </div>
      </template>
    </CSDialog>

    <CSDialog
        :dialog-show-confirm-btn="false"
        :dialog-title="policyDetailsInfo.headline"
        :dialog-visible="policyInfoVisible"
        dialog-cancel-btn-text="关闭"
        dialog-header-class="alert-bg"
        dialog-width="880px"
        @onClose="policyInfoVisible = false"
    >
      <template v-slot:dialog-content>
        <div style="padding: 30px 10px 30px 30px">
          <div
              style="
              overflow-y: auto;
              padding-right: 15px;
              text-align: justify;
              height: 474px;
              margin-bottom: 30px;
              border-bottom: 1px solid #ccc;
            "
              v-html="policyDetailsInfo.content"
          ></div>
          <div class="enclosure">
            <div class="enclosure_label">附件</div>
            <div
                v-if="
                policyDetailsInfo.files && policyDetailsInfo.files.length > 0
              "
                class="enclosure_name"
            >
              <p
                  v-for="policy in policyDetailsInfo.files"
                  :key="policy.url"
                  style="width: 720px"
              >
                <a
                    :href="policy.url"
                    style="text-decoration: underline; color: #1dafff"
                    target="_blank"
                >{{ policy.name }}</a
                >
              </p>
            </div>
            <div v-else>-</div>
          </div>
        </div>
      </template>
    </CSDialog>

    <CSDialog
        :dialogVisible="policyToBeIssuedVisible"
        dialog-confirm-btn-text="确定"
        dialog-header-class=" "
        dialog-header-icon="icon-menua-zu13"
        dialogTitle="提示"
        dialogWidth="480px"
        @onClose="policyToBeIssuedVisible = false"
        @onConfirm="deletePolicyToBeIssued"
    >
      <div
          slot="dialog-content"
          style="padding: 20px 30px; font-size: 24px; text-align: center"
      >
        确定删除吗?
      </div>
    </CSDialog>

    <CSDialog
        :dialog-show-confirm-btn="false"
        :dialog-visible="failCauseVisible"
        dialog-cancel-btn-text="关闭"
        dialog-title="抓取失败原因"
        dialog-width="480px"
        @onClose="failCauseVisible = false"
    >
      <template v-slot:dialog-content>
        <div style="padding: 30px; font-size: 24px; text-align: center">
          <div v-for="item in failCause" :key="item">{{ item }}</div>
        </div>
      </template>
    </CSDialog>

    <CSDialog
        :dialog-visible="addFilterWordsVisible"
        dialog-title="添加过滤词语"
        dialog-width="480px"
        @onClose="
        addFilterWordsVisible = false;
        terms = '';
      "
        @onConfirm="addFilterWords"
    >
      <template v-slot:dialog-content>
        <div
            style="
            padding: 30px;
            font-size: 24px;
            display: flex;
            align-items: center;
          "
        >
          <div class="table" style="margin-bottom: 0">过滤词语</div>
          <input
              v-model="terms"
              class="hy-input"
              placeholder="请输入"
              type="text"
          />
        </div>
      </template>
    </CSDialog>

    <CSDialog
        :dialog-show-confirm-btn="false"
        :dialog-visible="lookFilterWordsVisible"
        dialog-cancel-btn-text="关闭"
        dialog-title="过滤词语"
        dialog-width="480px"
        @onClose="lookFilterWordsVisible = false"
    >
      <template v-slot:dialog-content>
        <div style="padding: 30px; font-size: 24px; text-align: center">
          <div v-for="(item, index) in filterTextList" :key="item">
            {{ item }}
            <svg
                aria-hidden="true"
                class="icon"
                style="margin-left: 20px"
                @click="deleteFilterText(index)"
            >
              <use xlink:href="#icon-menushanchu"></use>
            </svg>
          </div>
        </div>
      </template>
    </CSDialog>
  </div>
</template>

<script>
import CSTabBar from "@/components/common/CSTabBar";
import CSSelect from "@/components/common/CSSelect";
import Pagination from "@/components/Pagination";
import CSDialog from "@/components/common/CSDialog";
import WangEditor from "@/components/WangEditor";
import dayjs from "dayjs";

import {
  addHandPoliceUrl,
  addPolicySelectUrlNameUrl,
  addSearchUrl,
  addWebUrl,
  deletePolicyUrl,
  deleteWebUrl,
  downLoadFileUrl,
  enableAutomaticGrabUrl,
  getSearchUrl,
  importExcelUrl,
  queryByConditionUrl,
  queryByGrabWebsiteUrl,
  queryByIdPolicyUrl,
  queryFailCauseUrl,
  queryPolicyByConditionUrl,
  queryPolicyInfoUrl,
  queryProvinceUrl,
  querySetDateUrl,
  selectFilterTextUrl,
  sendPolicyUrl,
  setFilterTextUrl,
  setGrabYearUrl,
  setupTimeUrl,
  testCrawSiteUrl,
  updatePolicyUrl,
  updateWebUrl,
} from "@/requestUrl";
import CSTable from "@/components/common/CSTable";

export default {
  name: "PolicyManagement",
  components: {
    CSTable,
    CSTabBar,
    CSSelect,
    Pagination,
    CSDialog,
    WangEditor,
  },
  data() {
    return {
      isLoadingC: false,
      isLoading: false,
      filterHeight: 0,
      grabFilterHeight: 0,
      isDisable: false,
      tabBarList: {
        0: "待发布",
        1: "已发布",
        2: "网站抓取设置",
        3: "热搜设置",
      },
      grabSettings: {
        0: "网站",
        1: "测试抓取",
        2: "自动抓取",
      },
      policyLevelList: [
        {
          id: 1,
          name: "国家级",
        },
        {
          id: 2,
          name: "省级",
        },
        {
          id: 3,
          name: "市级",
        },
        {
          id: 4,
          name: "区县级",
        },
      ],
      grabState: [
        {
          id: 1,
          name: "成功",
        },
        {
          id: 0,
          name: "失败",
        },
      ],
      releaseState: [
        {
          id: 0,
          name: "待发布",
        },
        {
          id: 1,
          name: "已发布",
        },
      ],
      policyLevel: {
        1: "国家级",
        2: "省级",
        3: "市级",
        4: "区县级",
      },
      captureStateList: {
        0: "未抓取",
        1: "抓取中",
        2: "已完成",
      },
      // 修改政策信息
      editPolicyByInfo: {
        releaseDate: "",
        headline: "",
        lever: "",
        websiteUrl: "",
        zoneApplication: "",
        content: "",
        accessory: [],
      },
      autoGrabTimeHourList: ["01", "02", "03", "04", "05", "06"],
      autoGrabTimeMinuteList: ["00", "15", "30", "45"],
      releaseGrabHourList: ["08", "09", "10", "11", "12"],
      tabBarType: 0,
      grabType: 0,
      //发布政策对话框显隐
      releasePolicyVisible: false,
      // 添加热搜词显隐
      hotSearchVisible: false,
      // 添加网站对话框显隐
      addWebsiteVisible: false,
      // Excel导入
      excelVisible: false,
      // 测试抓取对话框显隐
      testGrabVisible: false,
      // 启用自动抓取对话框显隐
      enableGrabVisible: false,
      isSubmitting: false,
      // 修改政策对话框显隐
      editPolicyVisible: false,
      // 政策详情弹窗显隐
      policyDetailsVisible: false,
      // 待发布删除弹框显隐
      policyToBeIssuedVisible: false,
      // 待发布政策列表
      policyToBeIssuedList: [],
      // 待发布政策条数
      policyTotal: "",
      // 已发布政策列表
      policyPublishedList: [],
      // 已发布政策查询开始时间
      startTime: dayjs().format("YYYY-MM-DD") + " 00:00",
      // 已发布政策查询结束时间
      endTime: dayjs().format("YYYY-MM-DD") + " 23:59",
      // 政策详情信息
      policyDetailsInfo: {},
      // 地区
      ProvinceList: [],
      // 查询抓取网站数据
      queryCrawlWebsiteInfo: {
        search: "",
        lever: "",
      },
      // 网站抓取设置-网站
      webList: [],
      // 判断添加还是修改
      conditionBtnType: "",
      // 添加抓取网站数据
      addCrawlWebsiteInfo: {
        organizationName: "",
        lever: "",
        zoneApplication: "",
        websiteUrl: "",
      },
      // 测试抓取查询数据
      queryTestGrab: {
        startTime: dayjs().format("YYYY-MM-DD") + " 00:00",
        endTime: dayjs().format("YYYY-MM-DD") + " 23:59",
        search: "",
        lever: "",
        zoneApplication: "",
        captureStatus: "",
        releaseStatus: "",
      },
      // 热搜词数据
      hotSearchInfo: {
        id: "",
        searchName1: "",
        searchName2: "",
        searchName3: "",
        searchName4: "",
      },
      // 热搜词
      hotSearchName: "",
      // 地区显隐
      regionVisible: false,
      // 地区
      regionName: "",
      // 省名
      provinceName: "",
      // 市名
      cityName: "",
      // 区名
      districtName: "",
      // 省
      province: "",
      // 市
      city: "",
      // 区县
      district: "",
      // 市级列表
      cityList: [],
      //  区县级列表
      districtList: [],
      // 添加网站中的地区列表
      addWebObj: {
        cityList: [],
        districtList: [],
      },
      // 添加网站中的地区名字
      addWebRegionName: {
        provinceName: "",
        cityName: "",
        districtName: "",
      },
      // 添加网站中选中地区的信息
      addWebRegionInfo: {
        provinceInfo: "",
        cityInfo: "",
        districtInfo: "",
      },
      deleteByConditionVisible: false,
      // 删除项id
      deleteId: "",
      // 测试抓取列表
      testGrabList: [],
      // 抓取年份下限列表
      particularYearList: [
        "2021",
        "2020",
        "2019",
        "2018",
        "2017",
        "2016",
        "2015",
        "2014",
        "2013",
        "2012",
        "2011",
        "2010",
        "2009",
        "2008",
        "2007",
        "2006",
        "2005",
        "2004",
        "2003",
        "2002",
        "2001",
        "2000",
      ],
      // 抓取年份下限
      particularYear: "2017",
      // 自动抓取列表
      automaticGrabList: [],
      // 自动抓取小时
      autoGrabTimeHour: "01",
      // 自动抓取分钟
      autoGrabTimeMinute: "00",
      // 自动抓取发布小时
      releaseGrabHour: "09",
      // 自动抓取发布分钟
      releaseGrabMinute: "00",
      // 是否启动自动抓取
      enable: "",
      // 修改时获取的政策详情
      editPolicyInfo: {},
      // 查看政策弹窗显隐
      policyInfoVisible: false,
      // 添加政策弹窗文案
      addPolicyDialogTitle: "",
      // 添加修改保存是判断的状态
      addEditState: "",
      // 修改的政策id
      editPolicyId: "",
      // 上传的excel文件
      fromData: "",
      // excel名称
      excelName: "",
      // 添加网站名称
      addWebTitle: "",
      // 修改网站时用的id
      editByConditionId: "",
      // 测试抓取中显隐
      testGrabInProgressVisible: false,
      // 抓取失败原因
      failCause: [],
      // 抓取失败原因弹窗显隐
      failCauseVisible: false,
      // 添加过滤词显隐
      addFilterWordsVisible: false,
      // 查看过滤词显隐
      lookFilterWordsVisible: false,
      // 添加过滤词的id
      addFilterWordsId: "",
      // 添加的过滤词
      terms: "",
      // 过滤词列表
      filterTextList: [],
      //计时器
      timer: null,
      webUrlList: [],
      pageParams: {
        pageNo: 1,
        pageSize: 10
      }
    };
  },
  watch: {
    "editPolicyByInfo.lever"(val) {
      val == 1 &&
      ((this.cityName = ""),
          (this.districtName = ""),
          (this.city = ""),
          (this.district = ""));
      val == 2 &&
      ((this.cityName = ""), (this.districtName = ""), (this.district = ""));
      val == 3 && (this.districtName = "");
      this.getWebUrl(val == 1 ? "中国" : "");
    },
  },
  mounted() {
    this.$vc.on(
        this.$route.path,
        "editVisitingService",
        "changeEditor",
        this.changeEditor
    );
    this.$vc.on(this.$route.path, "pagination", "page_event", (page) => {
      this.pageParams.pageNo = page;
      if (this.tabBarType == 0) {
        this.queryPolicyByCondition(page);
      } else if (this.tabBarType == 1) {
        this.queryPolicyPublished(page);
      } else if (this.tabBarType == 2) {
        if (this.grabType == 0) {
          this.queryByCondition(page);
        } else if (this.grabType == 1) {
          this.queryTestGrabList(page);
        } else if (this.grabType == 2) {
          this.queryAutomaticGrabList(page);
        }
      }
    });
    // 查询地区
    this.getProvince();
    // 查询待发布列表
    this.queryPolicyByCondition();
    const {filterPanel, grabFilterPanel} = this.$refs;
    if (filterPanel) {
      this.filterHeight = filterPanel.offsetHeight;
    }
    if (grabFilterPanel) {
      this.grabFilterHeight = grabFilterPanel.offsetHeight;
    }

    window.addEventListener("resize", () => {
      const {filterPanel, grabFilterPanel} = this.$refs;
      if (filterPanel) {
        this.filterHeight = filterPanel.offsetHeight;
      }
      if (grabFilterPanel) {
        this.grabFilterHeight = grabFilterPanel.offsetHeight;
      }
    });
  },
  updated() {
    const {filterPanel, grabFilterPanel} = this.$refs;
    if (filterPanel) {
      this.filterHeight = filterPanel.offsetHeight;
    }
    if (grabFilterPanel) {
      this.grabFilterHeight = grabFilterPanel.offsetHeight;
    }
  },
  methods: {
    endDisable(e) {
      if (e.keyCode == 13) {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.isDisable = true;
        }, 0);
      }
    },
    upDisable() {
      if (this.isDisable) {
        this.isDisable = false;
      }
    },

    upLoadFile() {
      $("#up_load_file").trigger("click");
    },
    chooseFile(event) {
      var photoFiles = event.target.files;
      if (photoFiles && photoFiles.length > 0) {
        // 获取目前上传的文件
        var file = photoFiles[0]; // 文件大小校验的动作
        this.$fly
            .putFile(file, (ossObject) => {
              (this.speedProgress = ossObject.progress),
                  (this.ossClient = ossObject.ossClient);
            })
            .then((res) => {
              this.editPolicyByInfo.accessory.push({
                name: res.fileName,
                url: res.url,
              });
            });
        event.target.value = "";
      }
    },
    upLoadExcelFile() {
      $("#up_excel_file").trigger("click");
    },
    chooseExcelFile() {
      var photoFiles = event.target.files;
      if (photoFiles && photoFiles.length > 0) {
        // 获取目前上传的文件
        var file = photoFiles[0]; // 文件大小校验的动作
        this.excelName = file.name;
        // if (file.size > 1024 * 1024 * 2) {
        //     this.$vc.toast("图片大小不能超过 2MB!");
        //     return false;
        // }
        var formData = new FormData(); //新建FileReader对象
        formData.append("file", file);
        this.formData = formData;
      }
    },
    uploadExcel() {
      this.$fly.upload(importExcelUrl, this.formData).then((res) => {
        if (res.code != 0) {
          this.$vc.toast("上传失败");
          return;
        }
        this.queryByCondition();
        this.$vc.toast("上传成功");
        this.excelVisible = false;
      });
    },
    // 修改tabular
    changeTabBar(type = 0) {
      this.pageParams.pageNo = 1;
      this.regionVisible = false;
      (this.province = ""), (this.city = "");
      this.district = "";
      this.provinceList = [];
      this.cityList = [];
      this.districtList = [];
      this.provinceName = "";
      this.cityName = "";
      this.districtName = "";
      this.regionName = "";
      this.tabBarType = type;
      this.grabType = 0;
      this.queryCrawlWebsiteInfo = {
        search: "",
        lever: "",
      };
      if (type === 3) {
        this.queryHotSearch();
      } else if (type === 2) {
        this.querySetDate();
        this.grabType = 0;
        this.queryByCondition();
      } else if (type === 1) {
        this.queryPolicyPublished();
        this.startTime = dayjs().format("YYYY-MM-DD") + " 00:00";
        this.endTime = dayjs().format("YYYY-MM-DD") + " 23:59";
      } else if (type === 0) {
        this.queryPolicyByCondition();
      }
    },
    // 修改网站抓取设置下的tabbar
    grabChange(type = 0) {
      this.pageParams.pageNo = 1;
      this.regionVisible = false;
      (this.province = ""), (this.city = "");
      this.district = "";
      this.provinceList = [];
      this.cityList = [];
      this.districtList = [];
      this.provinceName = "";
      this.cityName = "";
      this.districtName = "";
      this.regionName = "";
      this.grabType = type;
      this.queryTestGrab = {
        startTime: dayjs().format("YYYY-MM-DD") + " 00:00",
        endTime: dayjs().format("YYYY-MM-DD") + " 23:59",
        search: "",
        lever: "",
        zoneApplication: "",
        captureStatus: "",
        releaseStatus: "",
      };
      this.queryCrawlWebsiteInfo = {
        search: "",
        lever: "",
      };
      if (this.grabType === 0) {
        this.queryByCondition();
      } else if (this.grabType === 1) {
        this.queryTestGrabList();
      } else if (this.grabType === 2) {
        this.queryAutomaticGrabList();
      }
    },
    // 地区显隐
    switchVisible(e) {
      this.regionVisible = !this.regionVisible;
      e.stopPropagation();
    },
    switchVisibleHide() {
      this.regionVisible = false;
    },
    // 选择省事件
    onProvince() {
      if (this.province) {
        this.provinceName = this.province.provinceName;
        this.cityList = this.province.name;
        this.getWebUrl();
      } else {
        this.provinceName = "";
        this.cityName = "";
        this.districtName = "";
        this.city = "";
        this.district = "";
        this.cityList = [];
        this.districtList = [];
      }
    },
    // 选择市事件
    onCity() {
      if (this.city) {
        this.cityName = this.city.cityName;
        this.districtList = this.city.regions;
        this.getWebUrl();
      } else {
        this.cityName = "";
        this.districtName = "";
        this.district = "";
        this.districtList = [];
      }
    },
    onDistrict() {
      if (this.district) {
        this.districtName = this.district.name;
        this.getWebUrl();
      } else {
        this.districtName = "";
        this.districtList = [];
      }
    },
    // 确定地区按钮
    onRegionConfirm() {
      this.regionName = "";
      this.regionName = this.provinceName;
      this.cityName
          ? (this.regionName = this.regionName + "-" + this.cityName)
          : "";
      this.districtName
          ? (this.regionName = this.regionName + "-" + this.districtName)
          : "";
      this.regionVisible = false;
    },
    // 查询省市区
    async getProvince() {
      let res = await this.$fly.get(queryProvinceUrl, {
        code: 410000000000,
      });
      if (res.code != 0) {
        return;
      }
      this.ProvinceList = res.data.provinces;
    },
    // 查询待发布页面
    async queryPolicyByCondition(pageNo = 1, pageSize = 10) {
      let res = await this.$fly.post(queryPolicyByConditionUrl, {
        ...this.queryCrawlWebsiteInfo,
        releaseStatus: 0,
        pageNo,
        pageSize,
        zoneApplication: this.regionName,
      });
      if (res.code != 0) {
        return;
      }

      if (pageNo == 1) {
        this.$vc.emit(this.$route.path, "pagination", "init", {
          total: res.data.total,
          currentPage: pageNo,
          pageSize,
        });
      }
      this.policyToBeIssuedList = res.data.datas;
      if (this.policyToBeIssuedList.length > 0) {
        this.isLoading = false;
      } else {
        this.isLoading = true;
      }
      this.policyTotal = res.data.total;
    },
    // 添加/修改政策
    addEditPolicy(state, id) {
      if (state === 1) {
        this.addEditState = 1;
        this.addPolicyDialogTitle = "添加政策";
        this.editPolicyVisible = true;
      } else {
        this.addEditState = 2;
        this.editPolicyId = id;
        this.queryByIdPolicyInfo(id);
        this.addPolicyDialogTitle = "修改政策";
      }
    },
    // 手动添加，查询网站列表接口
    getWebUrl(name) {
      let zone = "";
      if (name) {
        zone = name;
      } else {
        this.provinceName ? (zone = this.provinceName) : (zone = "中国");
        this.cityName ? (zone = zone + "-" + this.cityName) : "";
        this.districtName ? (zone = zone + "-" + this.districtName) : "";
      }
      this.$fly
          .get(addPolicySelectUrlNameUrl, {
            zone,
          })
          .then((res) => {
            if (res.code != 0) {
              return;
            }
            this.webUrlList = res.data;
          });
    },
    // 修改政策详情接口
    async queryByIdPolicyInfo(id) {
      let res = await this.$fly.get(queryByIdPolicyUrl, {id});
      if (res.code != 0) {
        return;
      }
      let data = res.data;
      let policyInfo = {
        releaseDate: data.releaseDate,
        headline: data.headline,
        lever: data.lever,
        zoneApplication: data.zoneApplication,
        websiteUrl: data.websiteUrl,
        content: data.content,
        accessory: data.files || [],
      };
      this.editPolicyByInfo = policyInfo;
      this.handleRegion(data.zoneApplication, 1);
      let emit = setTimeout(() => {
        this.$vc.emit(
            this.$route.path,
            "wangEditor",
            "setWangEditorContent",
            data.content
        );
        clearTimeout(emit);
      });
      this.editPolicyVisible = true;
    },
    // 添加修改弹窗保存时判断
    judgeAddEdit() {
      this.addEditState === 1 ? this.addPolicy() : this.editPolicy();
    },
    // 添加政策
    async addPolicy() {
      let zoneApplication = "";
      zoneApplication = this.provinceName;
      this.cityName
          ? (zoneApplication = zoneApplication + "-" + this.cityName)
          : "";
      this.districtName
          ? (zoneApplication = zoneApplication + "-" + this.districtName)
          : "";
      this.editPolicyByInfo.content = this.editPolicyByInfo.content
          .replace("<p>", "")
          .replace("</p>", "");
      if (this.editPolicyByInfo.lever >= 2 && !this.provinceName) {
        this.$vc.toast("请选择省");
        return;
      }
      if (this.editPolicyByInfo.lever >= 3 && !this.cityName) {
        this.$vc.toast("请选择市");
        return;
      }
      if (this.editPolicyByInfo.lever == 4 && !this.districtName) {
        this.$vc.toast("请选择区县");
        return;
      }
      this.isSubmitting = true;
      let res = await this.$fly.post(addHandPoliceUrl, {
        ...this.editPolicyByInfo,
        zoneApplication: zoneApplication || "中国",
      });
      this.isSubmitting = false;
      if (res.code != 0) {
        this.$vc.toast("添加失败");
        return;
      }
      this.editPolicyVisible = false;
      if (this.tabBarType == 0) {
        this.queryPolicyByCondition();
      } else {
        this.queryPolicyPublished();
      }
      this.$vc.toast("添加成功");
    },
    // 修改政策
    async editPolicy() {
      this.editPolicyByInfo.content = this.editPolicyByInfo.content
          .replace("<p>", "")
          .replace("</p>", "");
      let zoneApplication = "";
      zoneApplication = this.provinceName;
      this.cityName
          ? (zoneApplication = zoneApplication + "-" + this.cityName)
          : "";
      this.districtName
          ? (zoneApplication = zoneApplication + "-" + this.districtName)
          : "";
      if (this.editPolicyByInfo.lever >= 2 && !this.provinceName) {
        this.$vc.toast("请选择省");
        return;
      }
      if (this.editPolicyByInfo.lever >= 3 && !this.cityName) {
        this.$vc.toast("请选择市");
        return;
      }
      if (this.editPolicyByInfo.lever == 4 && !this.districtName) {
        this.$vc.toast("请选择区县");
        return;
      }
      this.isSubmitting = true;
      let res = await this.$fly.post(updatePolicyUrl, {
        ...this.editPolicyByInfo,
        id: this.editPolicyId,
        zoneApplication: zoneApplication || "中国",
      });
      this.isSubmitting = false;
      if (res.code != 0) {
        this.$vc.toast("修改失败");
        return;
      }
      if (this.tabBarType == 0) {
        this.queryPolicyByCondition();
      } else {
        this.queryPolicyPublished();
      }
      this.editPolicyVisible = false;
      this.$vc.toast("修改成功");
    },
    addEditClose() {
      this.editPolicyByInfo = {
        releaseDate: "",
        headline: "",
        lever: "",
        zoneApplication: "",
        content: "",
        accessory: [],
      };
      this.province = "";
      this.city = "";
      this.district = "";
      this.cityList = [];
      this.districtList = [];
      this.provinceName = "";
      this.cityName = "";
      this.districtName = "";
      this.editPolicyVisible = false;
    },
    // 删除待发布政策
    async deletePolicyToBeIssued() {
      let res = await this.$fly.get(deletePolicyUrl, {
        id: this.deleteId,
      });
      if (res.code != 0) {
        this.$vc.toast("删除失败");
        return;
      }
      this.$vc.toast("删除成功");
      this.policyToBeIssuedVisible = false;
      await this.queryPolicyByCondition(1, 10);
      await this.queryPolicyPublished(1, 10);
    },
    // 发布政策按钮点击事件
    releasePolicy() {
      this.releasePolicyVisible = true;
    },
    async releasePolicyConfirm() {
      let res = await this.$fly.post(sendPolicyUrl, {
        ids: [-1],
      });
      if (res.code != 0) {
        this.$vc.toast("发布失败");
        return;
      }
      this.releasePolicyVisible = false;
      this.$vc.toast("发布成功");
      this.queryPolicyByCondition();
    },
    // 查询已发布页面
    async queryPolicyPublished(pageNo = this.pageParams.pageNo, pageSize = 10) {
      let res = await this.$fly.post(queryPolicyByConditionUrl, {
        ...this.queryCrawlWebsiteInfo,
        startTime: this.startTime,
        endTime: this.endTime,
        releaseStatus: 1,
        pageNo,
        pageSize,
        zoneApplication: this.regionName,
      });
      if (res.code != 0) {
        return;
      }
      if (pageNo === 1) {
        this.$vc.emit(this.$route.path, "pagination", "init", {
          total: res.data.total || res.data.length,
          currentPage: pageNo,
          pageSize: res.data.total ? pageSize : res.data.datas.length,
        });
      }
      this.policyPublishedList = res.data.datas;
    },
    // 点击查询按钮
    onQueryState() {
      this.tabBarType === 0 && this.queryPolicyByCondition();
      this.tabBarType === 1 && this.queryPolicyPublished();
      this.tabBarType === 2 && this.grabType === 0 && this.queryByCondition();
    },
    //回车查询
    onQueryStateDown(e) {
      if (e.keyCode == 13) {
        this.onQueryState();

        if (this.tabBarType == 2) {
          this.grabType === 1
              ? this.queryTestGrabList()
              : this.queryAutomaticGrabList();
        }
      }
    },
    // 添加热搜词点击按钮
    hotSearch() {
      this.hotSearchVisible = true;
    },
    // 添加网站按钮事件
    addWebsite() {
      this.conditionBtnType = 1;
      this.addWebTitle = "添加网站";
      this.addWebsiteVisible = true;
    },
    editWebsite(web) {
      this.addCrawlWebsiteInfo = {
        organizationName: web.organizationName,
        lever: web.lever,
        zoneApplication: web.zoneApplication,
        websiteUrl: web.websiteUrl,
      };
      this.editByConditionId = web.id;
      this.conditionBtnType = 2;
      this.addWebTitle = "修改网站";
      this.handleRegion(web.zoneApplication, 2);
      this.addWebsiteVisible = true;
    },
    // excel导入按钮事件
    importExcel() {
      this.excelVisible = true;
    },
    // 测试抓取按钮事件
    testGrab() {
      this.testGrabVisible = true;
    },
    // 启用
    enableGrab() {
      this.enableGrabVisible = true;
    },
    // 修改富文本编辑器
    changeEditor(html) {
      this.editPolicyByInfo.content = html;
    },
    // 网站抓取设置-网站-查询列表
    async queryByCondition(pageNo = this.pageParams.pageNo, pageSize = 10) {
      let res = await this.$fly.post(queryByConditionUrl, {
        ...this.queryCrawlWebsiteInfo,
        zoneApplication: this.regionName,
        pageNo,
        pageSize,
      });
      if (res.code != 0) {
        return;
      }
      if (pageNo === 1) {
        this.$vc.emit(this.$route.path, "pagination", "init", {
          total: res.data.total || res.data.length,
          currentPage: pageNo,
          pageSize: pageSize,
        });
      }
      this.webList = res.data.datas;
    },
    addWebProvince() {
      if (this.addWebRegionInfo.provinceInfo) {
        this.addWebRegionName.provinceName =
            this.addWebRegionInfo.provinceInfo.provinceName;
        this.addWebObj.cityList = this.addWebRegionInfo.provinceInfo.name;
      } else {
        this.addWebRegionName.provinceName = "";
        this.addWebRegionName.cityName = "";
        this.addWebRegionName.districtName = "";
        this.addWebObj.cityList = [];
        this.addWebObj.districtList = [];
      }
    },
    addWebCity() {
      if (this.addWebRegionInfo.cityInfo) {
        this.addWebRegionName.cityName =
            this.addWebRegionInfo.cityInfo.cityName;
        this.addWebObj.districtList = this.addWebRegionInfo.cityInfo.regions;
      } else {
        this.addWebRegionName.cityName = "";
        this.addWebRegionName.districtName = "";
        this.addWebObj.districtList = [];
      }
    },
    addWebDistrict() {
      if (this.addWebRegionInfo.districtInfo) {
        this.addWebRegionName.districtName =
            this.addWebRegionInfo.districtInfo.name;
      } else {
        this.addWebRegionName.districtName = "";
        this.addWebObj.districtList = [];
      }
    },
    // 添加修改按钮
    addeditByConditionBtn() {
      this.conditionBtnType == 1
          ? this.addByCondition()
          : this.editByCondition();
    },
    // 网站抓取设置-网站-添加
    async addByCondition() {
      let zoneApplication = "";
      zoneApplication = this.addWebRegionName.provinceName;
      this.addWebRegionName.cityName
          ? (zoneApplication =
              zoneApplication + "-" + this.addWebRegionName.cityName)
          : "";
      this.addWebRegionName.districtName
          ? (zoneApplication =
              zoneApplication + "-" + this.addWebRegionName.districtName)
          : "";
      if (
          this.addCrawlWebsiteInfo.lever >= 2 &&
          !this.addWebRegionName.provinceName
      ) {
        this.$vc.toast("请选择省");
        return;
      }
      if (
          this.addCrawlWebsiteInfo.lever >= 3 &&
          !this.addWebRegionName.cityName
      ) {
        this.$vc.toast("请选择市");
        return;
      }
      if (
          this.addCrawlWebsiteInfo.lever == 4 &&
          !this.addWebRegionName.districtName
      ) {
        this.$vc.toast("请选择区县");
        return;
      }
      let res = await this.$fly.post(addWebUrl, {
        ...this.addCrawlWebsiteInfo,
        zoneApplication:
            this.addCrawlWebsiteInfo.lever == 1 ? "中国" : zoneApplication,
      });
      if (res.code != 0) {
        this.$vc.toast("添加失败");
        return;
      }
      this.addWebsiteVisible = false;
      this.queryByCondition();
    },
    // 网站抓取设置-网站-修改
    async editByCondition() {
      let zoneApplication = "";
      zoneApplication = this.addWebRegionName.provinceName;
      this.addWebRegionName.cityName
          ? (zoneApplication =
              zoneApplication + "-" + this.addWebRegionName.cityName)
          : "";
      this.addWebRegionName.districtName
          ? (zoneApplication =
              zoneApplication + "-" + this.addWebRegionName.districtName)
          : "";
      if (
          this.addCrawlWebsiteInfo.lever >= 2 &&
          !this.addWebRegionName.provinceName
      ) {
        this.$vc.toast("请选择省");
        return;
      }
      if (
          this.addCrawlWebsiteInfo.lever >= 3 &&
          !this.addWebRegionName.cityName
      ) {
        this.$vc.toast("请选择市");
        return;
      }
      if (
          this.addCrawlWebsiteInfo.lever == 4 &&
          !this.addWebRegionName.districtName
      ) {
        this.$vc.toast("请选择区县");
        return;
      }
      let res = await this.$fly.post(updateWebUrl, {
        ...this.addCrawlWebsiteInfo,
        zoneApplication:
            this.addCrawlWebsiteInfo.lever == 1 ? "中国" : zoneApplication,
        id: this.editByConditionId,
      });
      if (res.code != 0) {
        this.$vc.toast("修改失败");
        return;
      }
      this.addWebsiteVisible = false;
      this.queryByCondition();
    },
    // 网站抓取设置-网站-删除
    async deleteByCondition() {
      let res = await this.$fly.get(deleteWebUrl, {id: this.deleteId});
      if (res.code != 0) {
        this.$vc.toast("删除失败");
        return;
      }
      this.deleteByConditionVisible = false;
      this.queryByCondition();
    },
    // 查询测试抓取数据列表接口
    async queryTestGrabList(pageNo = 1, pageSize = 10) {
      let res = await this.$fly.post(queryByGrabWebsiteUrl, {
        ...this.queryTestGrab,
        zoneApplication: this.regionName,
        type: 0,
        pageNo,
        pageSize,
      });
      if (res.code != 0) {
        return;
      }
      if (pageNo === 1) {
        this.$vc.emit(this.$route.path, "pagination", "init", {
          total: res.data.total || res.data.length,
          currentPage: pageNo,
          pageSize: pageSize,
        });
      }
      this.testGrabList = res.data.datas;
      if (this.testGrabList.length > 0) {
        this.isLoadingC = false;
      } else {
        this.isLoadingC = true;
      }
    },
    // 查询自动抓取数据列表接口
    async queryAutomaticGrabList(pageNo = 1, pageSize = 10) {
      let res = await this.$fly.post(queryByGrabWebsiteUrl, {
        ...this.queryTestGrab,
        zoneApplication: this.regionName,
        type: 1,
        pageNo,
        pageSize,
      });
      if (res.code != 0) {
        return;
      }
      if (pageNo === 1) {
        this.$vc.emit(this.$route.path, "pagination", "init", {
          total: res.data.total || res.data.length,
          currentPage: pageNo,
          pageSize: pageSize,
        });
      }
      this.automaticGrabList = res.data.datas;
    },
    // 设置抓取年份下限
    async setGrabYear() {
      let res = await this.$fly.post(setGrabYearUrl, {
        year: this.particularYear,
      });
      if (res.code != 0) {
        this.$vc.toast("保存失败");
        return;
      }
      this.$vc.toast("保存成功");
    },
    // 查询测试抓取年份下限和自动抓取发布时间
    async querySetDate() {
      let res = await this.$fly.get(querySetDateUrl);
      if (res.code != 0) {
        return;
      }
      this.particularYear = res.data.grabYear;
      this.autoGrabTimeHour = res.data.automaticGrabDate.slice(0, 2);
      this.autoGrabTimeMinute = res.data.automaticGrabDate.slice(3);
      this.releaseGrabHour = res.data.automaticReleaseDate.slice(0, 2);
      this.releaseGrabMinute = res.data.automaticReleaseDate.slice(3);
      this.enable = res.data.enableAutomaticGrab;
    },
    async setupTime() {
      let res = await this.$fly.post(setupTimeUrl, {
        automaticGrabDate:
            this.autoGrabTimeHour + ":" + this.autoGrabTimeMinute,
        automaticReleaseDate:
            this.releaseGrabHour + ":" + this.releaseGrabMinute,
      });
      if (res.code != 0) {
        this.$vc.toast("保存失败");
        return;
      }
      this.$vc.toast("保存成功");
    },
    // 测试抓取网站
    async testCrawSite() {
      this.testGrabVisible = false;
      this.testGrabInProgressVisible = true;
      this.$vc.toast("已开始测试抓取");
      let res = await this.$fly.post(testCrawSiteUrl, {
        date: this.particularYear,
      });
      this.testGrabInProgressVisible = false;
      if (res.code != 0) {
        this.$vc.toast("测试抓取失败");
        return;
      }
      this.queryTestGrabList();
      this.$vc.toast("测试抓取成功");
    },
    // 自动抓取的启动和关闭
    async enableAutomaticGrab() {
      this.enableGrabVisible = false;
      let res = await this.$fly.get(enableAutomaticGrabUrl, {
        enable: this.enable ? 0 : 1,
      });
      if (res.code != 0) {
        return;
      }
      this.querySetDate();
      if (this.enable) {
        this.$vc.toast("关闭自动抓取成功");
      } else {
        this.$vc.toast("启动自动抓取成功");
      }
    },
    // 点击标题查询详情
    async queryPolicyInfo(id) {
      let res = await this.$fly.get(queryPolicyInfoUrl, {
        id,
      });
      if (res.code != 0) {
        this.$vc.toast("获取政策详情失败");
        return;
      }
      this.policyDetailsInfo = res.data;
      this.policyInfoVisible = true;
    },
    // 保存热搜词
    saveHotSearch() {
      if (!this.hotSearchName) {
        this.$vc.toast("请输入热搜词");
        return;
      }
      if (this.hotSearchName.length < 2) {
        this.$vc.toast("字数不能小于2");
        return;
      }
      this.hotSearchInfo.id && delete this.hotSearchInfo.id;
      for (let key in this.hotSearchInfo) {
        if (!this.hotSearchInfo[key]) {
          this.hotSearchInfo[key] = this.hotSearchName;
          this.hotSearchVisible = false;
          return;
        }
      }
      this.$vc.toast("最多添加4个热搜词");
    },
    // 添加热搜词
    async addHotSearch() {
      this.hotSearchInfo.id && delete this.hotSearchInfo.id;
      let res = await this.$fly.post(addSearchUrl, this.hotSearchInfo);
      if (res.code != 0) {
        this.$vc.toast("保存失败");
        return;
      }
      this.$vc.toast("保存成功");
    },
    // 查询热搜词
    async queryHotSearch() {
      let res = await this.$fly.get(getSearchUrl);
      if (res.code != 0) {
        this.$vc.toast("获取热搜词失败");
        return;
      }
      res.data.searchName1 = res.data.searchName1 || "";
      res.data.searchName2 = res.data.searchName2 || "";
      res.data.searchName3 = res.data.searchName3 || "";
      res.data.searchName4 = res.data.searchName4 || "";
      this.hotSearchInfo = res.data;
    },
    // 政策弹窗删除
    deletePolicyFile(index) {
      this.editPolicyByInfo.accessory.splice(index, 1);
    },
    // 添加网站close事件
    addWebsiteClose() {
      this.addWebsiteVisible = false;
      this.addCrawlWebsiteInfo = {
        organizationName: "",
        lever: "",
        zoneApplication: "",
        websiteUrl: "",
      };
      this.addWebRegionInfo = {
        provinceInfo: "",
        cityInfo: "",
        districtInfo: "",
      };
      this.addWebRegionName = {
        provinceName: "",
        cityName: "",
        districtName: "",
      };
    },
    // 查询抓取失败原因
    async queryFailCause(id) {
      let res = await this.$fly.get(queryFailCauseUrl, {
        id,
      });
      if (res.code != 0) {
        return;
      }
      this.failCause = res.data.split(";");
      this.failCauseVisible = true;
    },
    // 处理返回来的地区
    handleRegion(region, state) {
      let regionNameList = region.split("-");
      if (regionNameList[0] == "中国") {
        return;
      }
      if (state == 1) {
        this.provinceName = regionNameList[0] || "";
        this.cityName = regionNameList[1] || "";
        this.districtName = regionNameList[2] || "";

        if (!this.provinceName) {
          return;
        }
        this.ProvinceList.forEach((item) => {
          if (item.provinceName === this.provinceName) {
            this.province = item;
            this.cityList = item.name;
          }
        });
        if (!this.cityName) {
          return;
        }
        this.cityList.forEach((item) => {
          if (item.cityName === this.cityName) {
            this.city = item;
            this.districtList = item.regions;
          }
        });
        if (!this.districtName) {
          return;
        }
        this.districtList.forEach((item) => {
          if (item.name == this.districtName) {
            this.district = item;
          }
        });
      } else {
        this.addWebRegionName.provinceName = regionNameList[0] || "";
        this.addWebRegionName.cityName = regionNameList[1] || "";
        this.addWebRegionName.districtName = regionNameList[2] || "";

        if (!this.addWebRegionName.provinceName) {
          return;
        }
        this.ProvinceList.forEach((item) => {
          if (item.provinceName === this.addWebRegionName.provinceName) {
            this.addWebRegionInfo.provinceInfo = item;
            this.addWebObj.cityList = item.name;
          }
        });
        if (!this.addWebRegionName.cityName) {
          return;
        }
        this.addWebObj.cityList.forEach((item) => {
          if (item.cityName === this.addWebRegionName.cityName) {
            this.addWebRegionInfo.cityInfo = item;
            this.addWebObj.districtList = item.regions;
          }
        });
        if (!this.addWebRegionName.districtName) {
          return;
        }
        this.addWebObj.districtList.forEach((item) => {
          if (item.name == this.addWebRegionName.districtName) {
            this.addWebRegionInfo.districtInfo = item;
          }
        });
      }
    },
    // 添加过滤词
    async addFilterWords() {
      let {code, data} = await this.$fly.get(selectFilterTextUrl, {
        id: this.addFilterWordsId,
      });
      if (code != 0) {
        return;
      }
      data = data || [];
      data.push(this.terms);
      let res = await this.$fly.post(setFilterTextUrl, {
        id: this.addFilterWordsId,
        filterText: data,
      });
      if (res.code != 0) {
        this.$vc.toast("设置失败");
        return;
      }
      this.$vc.toast("设置成功");
      this.queryByCondition();
      this.addFilterWordsVisible = false;
    },
    // 查询过滤词
    async queryFilterListText(id) {
      this.addFilterWordsId = id;
      let res = await this.$fly.get(selectFilterTextUrl, {
        id: id,
      });
      if (res.code != 0) {
        return;
      }
      this.filterTextList = res.data;
      this.lookFilterWordsVisible = true;
    },
    // 删除过滤词
    async deleteFilterText(index) {
      console.log(index);
      this.filterTextList.splice(index, 1);
      let res = await this.$fly.post(setFilterTextUrl, {
        id: this.addFilterWordsId,
        filterText: this.filterTextList,
      });
      if (res.code != 0) {
        this.$vc.toast("删除失败");
        return;
      }
      this.$vc.toast("删除成功");
      let {code} = await this.$fly.get(selectFilterTextUrl, {
        id: this.addFilterWordsId,
      });
      if (code != 0) {
        return;
      }
      this.filterTextList = res.data || [];
      if (this.filterTextList.length == 0) {
        this.lookFilterWordsVisible = false;
        this.queryByCondition();
      }
    },
    downloadExcel() {
      this.$fly.get(downLoadFileUrl).then((res) => {
        if (res.code != 0) {
          return;
        }
        window.open(res.data);
      });
    },
  },
  created() {
    window.addEventListener("keydown", this.onQueryStateDown);
    document.body.addEventListener("keyup", this.upDisable);
  },
  destroyed() {
    window.removeEventListener("keydown", this.onQueryStateDown);
    document.body.removeEventListener("keyup", this.upDisable);
    document.body.removeEventListener("click", this.switchVisibleHide);
  },
  deactivated() {
    window.removeEventListener("keydown", this.onQueryStateDown);
  },
};
</script>

<style lang="stylus" scoped>
.hotSearch {
  display: flex;
  align-items: center;

  div {
    display: flex;
    align-items: center;
  }

  .text {
    background-color: #D8FFF8;
    font-size: 14px;
    color: #00B694;
    border-radius: 15px;
    margin-right: 10px;
    padding: 5px 10px;
  }
}

.notice_title {
  display: inline-block;
  font-size: 24px;
  color: #000;
  text-align: right;
  margin-right: 40px;
  vertical-align: middle;
}

.select {
  display: flex;

  .level {
    margin-right: 20px;

    input {
      vertical-align: middle;
      margin-right: 10px;
    }

    label {
      font-size: 24px;
      vertical-align: middle;
      margin-bottom: 0;
    }
  }
}

.region {
  position: absolute;
  top: 40px;
  left: 0;
  display: flex;
  padding: 15px 10px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.enclosure {
  font-size: 24px;
  display: flex;
  align-items: flex-start;

  .enclosure_label {
    width: 48px;
    margin-right: 40px;
  }
}

.grabbing {
  background-color: #D8FFF8;
  color: #00B694;
  width: 70px;
  height: 30px;
  line-height: 30px;
  border-radius: 15px;
  text-align: center;
  margin-right: 10px;
}

.empty {
  background-color: #fff;
  color: #1ab394;
  box-shadow: none;
}

/deep/ .el-input--prefix .el-input__inner::placeholder {
  color: #999;
  font-weight: 500;
}
</style>
